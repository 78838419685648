const artworks = {
    1: {
      id: 1,
      title: '디지털 낭만',
      images: ['/assets/artwork1.jpg'],
      artist: '작가 이름 1',
      description: '이 작품은 디지털 시대의 낭만을 표현한 작품입니다...',
      year: '2024',
      medium: '디지털 아트',
      theme: '기술과 감성의 조화',
      technicalFeatures: '디지털 기법과 낭만적 표현의 융합',
      wav: '/wav/작가이름1.wav',
    },
    2: {
      id: 2,
      title: '숲을 이루는 생각의 뿌리',
      images: ['/assets/김조은/1.jpeg', '/assets/김조은/2.jpg', '/assets/김조은/3.jpg', '/assets/김조은/4.jpg', '/assets/김조은/5.jpg', '/assets/김조은/6.jpg', '/assets/김조은/7.jpg', '/assets/김조은/8.jpg'],
      artist: '김조은',
      description: `김조은 작가는 한국화라는 순수예술을 전공한 후 그 전통을 바탕으로 현대화된 설치와 회화작업으로 활동하는 작가입니다. 2006년부터 시각작업을 통해 그리움과 희망의 감정을 담은 기억의 풍경을 은유적으로 표현해 왔으며 최근 발표한 ‘달아 꽃이 되어라(2024)’에서는 달을 작가의 외할머니에 대한 그리움의 상징 또는 작가의 아이들을 위한 기도가 담긴 깊은 내면을 탐구하는 소재로 사용했습니다. 
  
      이번 ‘디지털 낭만’에서 소개된 ‘숲을 이루는 생각의 뿌리’ 작품은 상반신은 인간, 머리는 나무의 형태로 구성되어 인간의 사고와 자연의 생명력이 결합된 상징을 담고 있습니다. 인간의 모습은 이성과 지성을, 나무의 모습은 자연의 생명력과 성장, 무한한 확장을 상징합니다. 작품을 통해 사고의 성장과 자연의 융합을 표현하고자 하였습니다.`,
      year: '2024',
      medium: '혼합 매체 & 가변 설치',
      theme: '인간 사고와 자연의 융합',
      technicalFeatures: '상반신은 인간, 머리는 나무로 구성된 상징적 형태',
      wav: '/wav/김조은.wav',
    },
    3: {
      id: 3,
      title: 'Can you show me your dream?',
      images: ['/assets/김향금/1.jpg', '/assets/김향금/2.jpg', '/assets/김향금/3.jpg', '/assets/김향금/4.jpg', '/assets/김향금/5.jpg', '/assets/김향금/6.jpg', '/assets/김향금/7.jpg', '/assets/김향금/8.jpg'],
      artist: '김향금',
      description: `김향금 작가는 그림을 그리면서 글을 쓰는 시각예술가입니다. 회화, 에세이, 시 모두가 삶과 예술을 바라보는 철학적인 태도라고 생각하여 다양한 예술적 시도를 이어가고 있습니다. 서울, 파리, 브뤼셀, 대구 등에서 16회의 개인전을 하였으며 다수의 기획, 그룹전을 진행하였습니다. 
  
      작가의 최근 작업은 언어실험에 집중합니다. 이번 ‘디지털 낭만’에서 소개되는 ‘Can you show me your dream?’은 작품 속의 텍스트를 100명에게 질문하여 회화적으로 구현하는 작업으로, 소통 과정을 통해 ‘생명’에 대한 메시지를 담고 있습니다.`,
      year: '2024',
      medium: '회화 & 텍스트',
      theme: '소통과 생명',
      technicalFeatures: '텍스트와 회화를 결합한 인터랙티브 작업',
      wav: '/wav/김향금.wav',
    },
    4: {
      id: 4,
      title: '코스모폴리틱스',
      images: ['/assets/배지오/1.png', '/assets/배지오/2.png', '/assets/배지오/3.png', '/assets/배지오/4.png', '/assets/배지오/5.png'],
      artist: '배지오',
      description: `배지오 작가는 한국 출신의 미디어아티스트이자 미술교육자로 감각적인 아트워크와 독특한 스타일로 잘 알려져 있습니다. 이번 ‘디지털 낭만’에서는 자본주의의 폐허 위기에서 지구에 필요한 대안적 삶의 제시와 실천이라는 메시지를 담은 작품을 선보입니다. 
  
      작품은 AI가 생성한 생물의 다양성을 표현하는 영상과 관람객이 실시간으로 생성하는 사운드가 결합된 인터랙션 아트로, 인간과 자연의 공생 메시지를 전달합니다.`,
      year: '2024',
      medium: '인터랙티브 비디오',
      theme: '인간과 자연의 공생',
      technicalFeatures: 'AI 생성 영상과 실시간 사운드 인터랙션',
      wav: '/wav/배지오.wav',
    },
    5: {
      id: 5,
      title: '길에서 만나는 역사, 조선인 강제동원 흔적을 찾아 일본일주',
      images: ['/assets/우동윤/1.jpg', '/assets/우동윤/2.jpg','/assets/우동윤/3.jpg'  ],
      artist: '우동윤',
      description: `우동윤 작가는 대구를 기반으로 다큐멘터리 사진작업을 하고 있습니다. 광복 80주년을 앞두고 일제강점기 조선인 강제 동원의 흔적을 찾아 일본을 일주하며 사진으로 기록하였습니다. 
  
      이번 ‘디지털 낭만’에서는 작가가 촬영한 사진을 기반으로 여정 전체를 시각화하는 설치작품을 선보입니다. 오토바이와 현장을 생생하게 담은 설치를 통해 역사적 기억을 되새깁니다.`,
      year: '2024',
      medium: '가변 설치',
      theme: '역사적 기억과 추적',
      technicalFeatures: '사진 기반 시각화 설치와 오토바이 전시',
      wav: '/wav/우동윤.wav',
    },
    6: {
      id: 6,
      title: '무지개',
      images: ['/assets/이안민지/1.jpeg', '/assets/이안민지/2.jpeg','/assets/이안민지/3.jpeg','/assets/이안민지/4.jpeg','/assets/이안민지/5.jpeg'],
      artist: '이안민지',
      description: `이안민지 작가는 무지개의 형상과 색상을 이용한 설치 작업과 행복에 관한 평면작업을 병행합니다. 
  
      이번 작품 ‘무지개’는 희망을 상징하며, 천장에서 바닥까지 무지개빛 섬유를 행잉하여 공간 전체를 무지개로 조성하였습니다. 관람객은 섬유의 질감을 느끼며 희망의 메시지를 경험할 수 있습니다.`,
      year: '2024',
      medium: '가변 설치 & 패브릭',
      theme: '희망과 꿈',
      technicalFeatures: '섬유 행잉을 통한 대형 무지개 설치',
      wav: '/wav/이안민지.wav',
    },
    7: {
      id: 7,
      title: '.obj',
      images: ['/assets/전세주/전세주1.jpg', '/assets/전세주/전세주6.png', '/assets/전세주/전세주7.png', '/assets/전세주/전세주8.png', '/assets/전세주/전세주9.png', '/assets/전세주/전세주10.png', '/assets/전세주/전세주11.png', '/assets/전세주/전세주14.png'],
      artist: '전세주',
      description: `전세주 작가는 시각예술가 겸 큐레이터로, 공간에서 파생된 감정과 기억을 드로잉으로 담아냅니다. 
  
      작품 ‘.obj’는 추상적 형태의 물건을 얇은 나무 판자에 재단하여 격자 그물에 엮은 작업으로, 오브제의 형태성을 강조하며 사물의 주관성과 객관성을 탐구합니다.`,
      year: '2024',
      medium: '혼합 매체 & 패널, 그물',
      theme: '사물의 형태성과 주관성',
      technicalFeatures: '나무 판자 재단과 격자 그물 엮기',
      wav: '/wav/전세주.wav',
    },
    8: {
      id: 8,
      title: '제로 그래비티_더 플래닛',
      images: ['/assets/조민선/1.jpg', '/assets/조민선/2.jpg', '/assets/조민선/3.jpg'],
      artist: '조민선',
      description: `조민선 작가는 인간과 사물의 관계성을 탐구하며, 인간중심주의를 비판하는 설치 및 영상 작업을 진행합니다. 
  
      ‘제로 그래비티_더 플래닛’은 무중력 공간에서 부유하는 사물들을 통해 평등한 공생관계를 표현하며, 관람객에게 새로운 시각적 경험을 제공합니다.`,
      year: '2023',
      medium: '비디오 설치',
      theme: '평등과 공생',
      technicalFeatures: '무중력 공간 연출과 인터랙티브 비디오',
      wav: '/wav/조민선.wav',
    },
    9: {
      id: 9,
      title: 'And, It last..',
      images: ['/assets/artwork9.jpg'],
      artist: '최원규',
      description: `최원규 작가는 인간 존재와 삶에 대한 성찰을 시각 언어로 표현합니다. 
  
      작품 ‘And, It last..’는 대만 레지던시 기간 동안 만난 사람들의 이야기를 한지 오브제에 텍스트로 새겨 넣은 작업으로, 관람객에게 삶의 기억과 존재에 대해 질문을 던집니다.`,
      year: '2024',
      medium: '비디오 매핑 & 가변 설치',
      theme: '기억과 존재',
      technicalFeatures: '한지 오브제와 비디오 매핑 결합',
      wav: '/wav/최원규.wav',
    },
    10: {
      id: 10,
      title: 'The Hole No.1 & No.2',
      images: ['/assets/최유진/1.JPG', '/assets/최유진/2.jpeg', '/assets/최유진/3.jpeg'],
      artist: '최유진',
      description: `최유진 작가는 보이지 않지만 존재하는 불안과 소통의 여백을 시각화하는 작업을 합니다. 
  
      두 개의 박스로 이루어진 ‘The Hole’ 시리즈는 관람객이 제한된 구멍을 통해 내부를 관찰하며 시각적, 경험적 인식을 탐구하게 합니다.`,
      year: '2024',
      medium: '혼합 매체 & 가변 크기',
      theme: '불안과 소통의 여백',
      technicalFeatures: '구멍과 그물망을 통한 내부 관찰 구조',
      wav: '/wav/최유진.wav',
    },
  };
  
  export default artworks;
  