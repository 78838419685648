import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import artworks from '../data/Data';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const ArtworkImage = ({ images, title, artwork }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [audioElement, setAudioElement] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { artworkId } = useParams();
  const [responseAudio, setResponseAudio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    transcript: currentTranscript,
    listening,
    resetTranscript,
  } = useSpeechRecognition({
    commands: [],
  });

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log("브라우저가 음성 인식을 지원하지 않습니다.");
  }

  useEffect(() => {
    setShowWelcome(true);


    const audio = new Audio(artwork.wav);
    setAudioElement(audio);

    return () => {
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
    };
  }, [artwork.wav]);

  const toggleAudio = () => {
    if (audioElement) {
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === images.length - 1 ? 0 : prev + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? images.length - 1 : prev - 1
    );
  };

  const startRecording = async () => {
    if (audioElement && isPlaying) {
      audioElement.pause();
      audioElement.currentTime = 0;
      setIsPlaying(false);
    }

    resetTranscript();
    await SpeechRecognition.startListening({ language: 'ko-KR', continuous: true });
    setIsRecording(true);
  };

  const handleQuestionSubmit = async (text) => {
    setIsLoading(true);
    try {
      const response = await fetch('http://localhost:5000/api/answer-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: text,
          artwork_data: artwork
        })
      });

      const data = await response.json();
      
      if (data.success) {
        // 기존 오디오 중지
        if (audioElement) {
          audioElement.pause();
          audioElement.currentTime = 0;
          setIsPlaying(false);
        }

        // 새로운 응답 오디오 생성 및 재생
        const newAudio = new Audio(`http://localhost:5000${data.audio_path}`);
        newAudio.onended = () => setIsPlaying(false);
        newAudio.play();
        setIsPlaying(true);
        setResponseAudio(newAudio);
      }
    } catch (error) {
      console.error('API 요청 오류:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const stopRecording = () => {
    SpeechRecognition.stopListening();
    setIsRecording(false);
    console.log('인식된 음성:', currentTranscript);
    
    // 음성 인식이 끝나면 API로 전송
    if (currentTranscript) {
      handleQuestionSubmit(currentTranscript);
    }
  };

  const handleCharacterClick = () => {
    if (audioElement) {
      audioElement.currentTime = 0;
      setIsPlaying(true);
      setIsMinimized(true);
      setShowWelcome(false);
      audioElement.play().catch(error => {
        console.error('오디오 재생 중 오류:', error);
      });
      
      audioElement.onended = () => {
        setIsPlaying(false);
      };
    }
  };

  return (
    <div className="relative">
      <div className="relative pt-10 group bg-black flex justify-center items-center" style={{ height: '500px' }}>
      <button
          onClick={prevImage}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white/50 hover:bg-white/80 p-2 rounded-full transition-all"
        >
          <span className="text-2xl">←</span>
        </button>

        <img 
          src={images[currentImageIndex]} 
          alt={title}
          className="max-w-full max-h-full object-contain"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/assets/meta-favicon.png';
          }}
        />

        <button
          onClick={nextImage}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white/50 hover:bg-white/80 p-2 rounded-full transition-all"
        >
          <span className="text-2xl">→</span>
        </button>
        <div className="absolute top-[40%] md:top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2">
          {showWelcome && (
            <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-white/90 text-gray-800 px-4 py-2 rounded-lg shadow-lg whitespace-nowrap z-10">
              저를 누르면 작품에 대해서 설명해드릴게요.
            </div>
          )}
          <img 
            src="/assets/character.png" 
            alt="AI Docent" 
            onClick={handleCharacterClick}
            className={`cursor-pointer transition-all duration-300
              ${isMinimized 
                ? 'w-[100px] fixed bottom-20 right-8' 
                : 'w-[300px] md:w-[500px] floating'}
              ${isRecording ? 'animate-pulse' : ''}`}
          />
        </div>
      </div>

      <div className="fixed bottom-8 right-8 z-50 flex items-center gap-4">
        {isRecording && (
          <div className="bg-white/90 p-4 rounded-lg shadow-lg">
            <p className="text-gray-800">
              {currentTranscript || "말씀해 주세요..."}
            </p>
          </div>
        )}
        {isLoading && (
          <div className="bg-blue-500 text-white px-4 py-2 rounded-lg">
            응답 처리중...
          </div>
        )}
        <button
          onClick={() => isRecording ? stopRecording() : startRecording()}
          className={`bg-white rounded-full p-4 shadow-lg hover:scale-110 transition-transform
            ${isRecording ? 'bg-red-500' : 'bg-white'}`}
        >
          AI 도슨트에게 질문하기
        </button>
      </div>

      {audioElement && (
        <button
          onClick={toggleAudio}
          className="fixed top-4 right-4 bg-white/90 text-gray-800 px-4 py-2 rounded-lg shadow-lg z-50"
        >
          {isPlaying ? '그만듣기' : '다시듣기'}
        </button>
      )}
    </div>
  );
};

const ArtworkInfo = ({ artwork }) => (
  <div className="p-8 bg-black text-white">
    <div className="border-b border-gray-700 pb-4 mb-6">
      <h1 className="text-4xl font-bold mb-3 custom-font">{artwork.title}</h1>
      <p className="text-2xl custom-font">{artwork.artist}</p>
    </div>
    
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold custom-font">작품 설명</h2>
        <p className="leading-relaxed whitespace-pre-line custom-font">
          {artwork.description}
        </p>
      </div>
      
      <div className="bg-black border border-gray-700 p-6 rounded-lg">
        <h2 className="text-xl font-semibold mb-4 custom-font">작품 정보</h2>
        <dl className="space-y-2">
          <div className="flex justify-between">
            <dt className="custom-font">제작 연도</dt>
            <dd className="custom-font">{artwork.year}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="custom-font">매체</dt>
            <dd className="custom-font">{artwork.medium}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="custom-font">주제</dt>
            <dd className="custom-font">{artwork.theme}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
);

const ArtworkDetail = () => {
  const { artworkId } = useParams();
  const navigate = useNavigate();
  const artwork = artworks[artworkId];

  if (!artwork) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-500 via-blue-500 to-indigo-600 py-8 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="bg-white rounded-2xl shadow-xl p-8">
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-500 via-blue-500 to-indigo-600 py-8 px-4">
      <div className="flex justify-center mb-[20px]">
        <img 
          src="/assets/Group 109-1.png" 
          alt="META Logo" 
          className="h-12"
        />
      </div>
      <div className="max-w-6xl mx-auto">
        <button 
          onClick={() => navigate('/docent')}
          className="flex items-center text-white mb-6 hover:text-gray-200 transition-colors"
        >
          <span className="mr-2">←</span> 돌아가기
        </button>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <ArtworkImage images={artwork.images} title={artwork.title} artwork={artwork} />
          <ArtworkInfo artwork={artwork} />
        </div>
      </div>
    </div>
  );
};

export default ArtworkDetail;
